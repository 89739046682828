import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'

import type { IVehicleFilterParam, IVehicleService } from './vehicle.types'
import type { IVehicleData } from '@/models/fleet-management'

/**
 * A class representing a VehicleService API handler.
 */
export class VehicleService implements IVehicleService {
  private endpoint: string

  // VehicleService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/fleet/vehicles/'
  }
  // Method to fetch a list of Vehicles from the API
  fetchVehicles(filter?: IVehicleFilterParam): Promise<IVehicleData[]> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined

    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single Vehicle by their ID from the API
  fetchVehicle(id: number): Promise<IVehicleData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new Vehicle on the API
  createVehicle(data: IVehicleData): Promise<IVehicleData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing Vehicle on the API
  updateVehicle(data: IVehicleData): Promise<IVehicleData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a Vehicle by their ID from the API
  deleteVehicle(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the VehicleService
const vehicleService = new VehicleService(axios)
export default vehicleService
