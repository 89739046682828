<template>
  <v-autocomplete
    label="Model"
    v-model="vehicle"
    :items="vehicleModels"
    v-model:search="search"
    item-value="id"
    :item-title="
      (item: IVehicleModel) => {
        return item instanceof VehicleModel ? item.toString() : item
      }
    "
    :loading="isLoading"
    :error-messages="combinedErrorMessages"
    no-filter
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from 'vue'

import { useFetchAllVehicleModels } from '@/composables/vehicle-model'
import { isArray } from 'lodash'

import {
  VehicleModel,
  type IVehicleModel,
  type IVehicleModelData
} from '@/models/fleet-management/vehicle'

const props = defineProps({
  errorMessages: {
    type: [String, Array] as PropType<string | string[] | null>,
    default: () => []
  }
})

const vehicle = defineModel<null | number | IVehicleModelData>({ default: null })
const search = ref('')

const { vehicleModels, error: fetchError, isLoading } = useFetchAllVehicleModels()

const combinedErrorMessages = computed(() => {
  let error: string[] = []

  if (fetchError.value) {
    error.push(fetchError.value.message)
  }
  if (isArray(props.errorMessages)) {
    error.concat(props.errorMessages)
  } else if (props.errorMessages) {
    error.push(props.errorMessages)
  }

  return error
})
</script>
