<template>
  <div
    class="treeview-node"
    ref="treeNodeRef"
    :style="nodePositionStyles"
    :class="{ 'treeview-node__selected': isNodeSelected }"
  >
    <v-card
      class="treeview-node__content"
      :id="`treeview-node-${node.isOptionNode() ? 'option-' + node.option.id : 'field-' + node.field.id}`"
      :width="cardWidth"
      :variant="!isNodeSelected ? 'outlined' : 'elevated'"
      @mousedown.stop
      :theme="node.isOptionNode() ? 'dark' : 'light'"
      :class="node.isOptionNode() ? 'bg-grey-darken-1' : 'bg-white'"
      @click.stop="!!node.field.id ? selectNode(node) : () => {}"
    >
      <v-tooltip
        v-if="!!node.field.id || isVehicleLogNode"
        location="bottom"
        open-delay="450"
        :text="nodeFieldTypeLabel"
        theme="light"
      >
        <template #activator="{ props }">
          <v-icon
            :theme="node.isOptionNode() ? 'dark' : 'light'"
            v-bind="props"
            style="left: 6px; top: 6px"
            class="position-absolute rounded-circle"
            size="14"
            color="medium-emphasis"
            >{{ nodeFieldTypeIcon }}</v-icon
          >
        </template>
      </v-tooltip>

      <v-btn
        v-if="!!node.field.id"
        class="position-absolute rounded-circle"
        style="right: 0px; top: 0px"
        icon
        density="compact"
        variant="text"
        @click.stop="deleteNodeAndChildren(node)"
      >
        <v-icon size="16" color="medium-emphasis">mdi-delete</v-icon>
      </v-btn>

      <div class="px-3 py-6">
        <div class="d-flex flex-row text-center align-center justify-center">
          <div style="font-size: 14px; font-weight: 500; line-height: 1.75">
            {{ nodeLabel }}
          </div>
        </div>
      </div>
    </v-card>

    <div
      v-if="!isVehicleLogNode"
      class="treeview-node-connection-after"
      ref="treeNodeConnectionAfterRef"
      :style="nodeConnectorPositionAfterStyles()"
      :id="`treeview-node-${node.isOptionNode() ? 'option-' + node.option.id : 'field-' + node.field.id}-button`"
    >
      <div
        class="position-absolute rounded-circle"
        :style="nodeConnectionOptionButtonStyles()"
        v-if="node.isLeaf() || node.hasOptionNodeChildren()"
      >
        <v-tooltip
          location="bottom"
          :text="`Add ${isNodeTypeFieldOption(node) ? 'Option' : 'Field'}`"
        >
          <template #activator="{ props }">
            <v-btn
              class="action-btn rounded-circle"
              :variant="node.hasOptionNodeChildren() ? 'elevated' : 'elevated'"
              :color="node.hasOptionNodeChildren() ? 'secondary' : 'success lighten-2'"
              :size="btnSize"
              style="z-index: 9999"
              @click.stop="
                isNodeTypeFieldOption(node)
                  ? addFieldOptionAfterNode(node)
                  : addFieldAfterNode(node)
              "
              icon
              v-bind="props"
            >
              <v-icon
                density="compact"
                :icon="node.hasOptionNodeChildren() ? 'mdi-lan' : 'mdi-plus'"
              ></v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </div>
    </div>
  </div>

  <template v-for="(child, index) in node.children" :key="index">
    <PostOrderFieldTreeViewChildren
      :node="child"
      :node-index="index"
      :is-vehicle-log-node="isVehicleLogNode"
      :level="level + 1"
      :coordinate="calculateChildNodeCoordinate(index)"
      @add-field-node="addFieldAfterNode"
      @add-option-node="addFieldOptionAfterNode"
      @delete-node="deleteNodeAndChildren"
    />
  </template>
</template>

<script setup lang="tsx">
import { computed, inject, ref } from 'vue'

import { type ICoordinate, type IPostOrderNode } from '@/models/post-order/tree'

import { FormFieldTypeEnum } from '@/models/form'
import { PostOrderNodeSymbol } from '../postOrderProvide'

interface Props {
  node: IPostOrderNode
  level: number
  coordinate: ICoordinate
  nodeIndex?: number
  isVehicleLogNode: boolean
}

const props = withDefaults(defineProps<Props>(), {
  level: 1,
  nodeIndex: 0
})

interface Emits {
  (name: 'add-option-node', node: IPostOrderNode): void
  (name: 'add-field-node', node: IPostOrderNode): void
  (name: 'delete-node', node: IPostOrderNode): void
}

const emit = defineEmits<Emits>()

const treeNodeRef = ref<HTMLDivElement>()
const treeNodeConnectionAfterRef = ref<HTMLDivElement>()

const btnSize = ref(28)

const yPaddingFactor = ref(150)
const xPaddingFactor = ref(50)
const cardWidth = ref(250)

const nodePositionStyles = computed(() => ({
  top: `${props.coordinate.y}px`,
  left: `${props.coordinate.x}px`
}))

const nodeLabel = computed(() => {
  let label = props.isVehicleLogNode ? 'Vehicle Log' : props.node.field.field.label

  if (props.node.option) {
    label = props.node.option.toString()
  }

  return label
})

function calculateSubtreeWidth(node: IPostOrderNode): number {
  // Base case: if the node is a leaf, the width is just the card's width
  if (node.isLeaf()) {
    return cardWidth.value
  }

  // Sum up the widths of all children, including padding between them
  let totalWidth = 0
  for (const child of node.children) {
    totalWidth += calculateSubtreeWidth(child) + xPaddingFactor.value
  }

  // Remove the extra padding added after the last child
  totalWidth -= xPaddingFactor.value

  return totalWidth
}

function calculateChildNodeCoordinate(index: number): ICoordinate {
  const calculatedY = yPaddingFactor.value + props.coordinate.y

  // Calculate the total width of the current node's subtree
  const parentWidth = calculateSubtreeWidth(props.node)

  // Calculate the total width up to the current child
  let widthOffset = 0
  for (let i = 0; i < index; i++) {
    widthOffset += calculateSubtreeWidth(props.node.children[i]) + xPaddingFactor.value
  }

  // Calculate the center offset for the parent
  const centerOffset = parentWidth / 2 - calculateSubtreeWidth(props.node.children[index]) / 2

  // The x-coordinate of the child node, ensuring the parent's center aligns with its children
  const calculatedX = props.coordinate.x - centerOffset + widthOffset

  return {
    y: calculatedY,
    x: calculatedX
  }
}

function nodeConnectorPositionAfterStyles() {
  return {
    height: `${yPaddingFactor.value / 4 + btnSize.value / 2}px`,
    width: `${cardWidth.value}px`,
    left: 0
  }
}

function nodeConnectionOptionButtonStyles() {
  return {
    left: `calc(50% - ${btnSize.value / 2}px)`,
    top: `calc(100% - ${btnSize.value}px)`,
    zIndex: '4'
  }
}

function addFieldOptionAfterNode(node: IPostOrderNode) {
  emit('add-option-node', node)
}
function addFieldAfterNode(node: IPostOrderNode) {
  emit('add-field-node', node)
}

function deleteNodeAndChildren(node: IPostOrderNode) {
  emit('delete-node', node)
}

const postOrderNodeContext = inject(PostOrderNodeSymbol)

if (!postOrderNodeContext)
  throw new Error('[Post Order Node] Could not find injected post order node context')

const isNodeSelected = computed(() => {
  if (!postOrderNodeContext.selectedNode.value) return false
  return props.node.isOptionNode()
    ? postOrderNodeContext.selectedNode.value.option?.id == props.node.option.id
    : postOrderNodeContext.selectedNode.value.field.id == props.node.field.id
})

const selectNode = (node: IPostOrderNode) => {
  if (node == postOrderNodeContext.selectedNode.value) {
    // deselect if we are selecting the same node
    postOrderNodeContext.selectNode()
  } else {
    postOrderNodeContext.selectNode(node)
  }
}

function isNodeTypeFieldOption(node: IPostOrderNode): boolean {
  if (node.isOptionNode()) {
    return false
  }
  switch (node.field.field.type) {
    case FormFieldTypeEnum.ComboBox:
    case FormFieldTypeEnum.CheckBox:
    case FormFieldTypeEnum.RadioButton:
      return true
    default:
      return false
  }
}
const nodeFieldTypeLabel = computed(() => {
  if (props.isVehicleLogNode) {
    return 'Vehicle Log'
  }
  if (props.node.isOptionNode()) {
    return 'Field option'
  }
  switch (props.node.field.field.type) {
    case FormFieldTypeEnum.Label:
      return 'Label'

    case FormFieldTypeEnum.TextArea:
      return 'Long input field'

    case FormFieldTypeEnum.ComboBox:
      return 'Dropdown field'

    case FormFieldTypeEnum.CheckBox:
      return 'Multiple option field'

    case FormFieldTypeEnum.RadioButton:
      return 'Radio button field'

    case FormFieldTypeEnum.FileField:
      return 'Image picker field'

    case FormFieldTypeEnum.TextField:
      return 'Short input box field'

    default:
      return ''
  }
})
const nodeFieldTypeIcon = computed(() => {
  if (props.isVehicleLogNode) {
    return 'mdi-car'
  }
  if (props.node.isOptionNode()) {
    return 'mdi-circle-double'
  }
  switch (props.node.field.field.type) {
    case FormFieldTypeEnum.Label:
      return 'mdi-format-text'

    case FormFieldTypeEnum.TextArea:
      return 'mdi-form-textbox'

    case FormFieldTypeEnum.ComboBox:
      return 'mdi-form-dropdown'

    case FormFieldTypeEnum.CheckBox:
      return 'mdi-order-bool-ascending-variant'

    case FormFieldTypeEnum.FileField:
      return 'mdi-image'

    default:
      return 'mdi-solid'
  }
})
</script>

<style lang="scss">
.treeview-node {
  position: absolute !important;
  border-radius: 4px !important;
  user-select: none;

  & __content {
    display: flex !important;
    flex-direction: column !important;
    transition: all 20ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

.treeview-node-connection-before {
  position: absolute !important;
}
.treeview-node-connection-after {
  position: absolute !important;
}

.treeview-node__selected {
  --node-border-discovery: #8f7ee7;
  animation: 3000ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s infinite normal none running pulsate;
  box-shadow: 0 0 0 2px var(--node-border-discovery, #6554c0);
}

@keyframes pulsate {
  0% {
    box-shadow:
      0 0 0 2px var(--node-border-discovery, #6554c0),
      0 0 0 var(--node-border-discovery, rgba(101, 84, 192, 1));
  }
  33% {
    box-shadow:
      0 0 0 2px var(--node-border-discovery, #6554c0),
      0 0 0 var(--node-border-discovery, rgba(101, 84, 192, 1));
  }

  66% {
    box-shadow:
      0 0 0 2px var(--node-border-discovery, #6554c0),
      0 0 0 10px rgba(101, 84, 192, 0.01);
  }
  100% {
    box-shadow:
      0 0 0 2px var(--node-border-discovery, #6554c0),
      0 0 0 10px rgba(101, 84, 192, 0.01);
  }
}
</style>
