<template>
  <v-card
    variant="flat"
    density="compact"
    min-height="60"
    color="background"
    class="border border-b-0 rounded-t-lg rounded-b-0 px-1 instruction-description"
    :class="{
      'instruction-description-expanded': isDescriptionPreview
    }"
  >
    <ErrorAlert
      v-if="error != null"
      dense
      :error="error"
      @clearErrors="clearErrors"
      class="mb-0 mt-4 rounded"
    />
    <div
      class="pa-4 position-relative"
      :class="{
        'mask-bottom-card': !isDescriptionPreview
      }"
    >
      {{ instruction.template.description }}
      <v-tooltip :text="`${isDescriptionPreview ? 'Shrink' : 'Expand'} description preview`">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            @click="toggleDescriptionPreview()"
            class="position-absolute rounded-circle"
            style="right: 12px; top: 12px"
            color="medium-emphasis"
            variant="text"
            size="x-small"
            :icon="isDescriptionPreview ? 'mdi-chevron-down' : 'mdi-chevron-up'"
          ></v-btn>
        </template>
      </v-tooltip>
    </div>
  </v-card>

  <v-layout full-height class="flex-fill" style="height: calc(-60px + 100vh)">
    <PostOrderInstructionDetailSectionList
      :post-order-id="postOrderId"
      :post-order-instruction-id="postOrderInstructionId!"
      :sections="instruction.sections"
      :selected-section="selectedSection?.id"
      @edit-section-pressed="openPostOrderInstructionSectionFormDialog"
      @select-section-pressed="(section) => selectSection(section.id!)"
      @create-post-order-instruction-section-pressed="openPostOrderInstructionSectionFormDialog()"
    />

    <PostOrderInstructionDetailSectionFormDialog
      v-model:dialog="isPostOrderInstructionSectionFormDialogActive"
      :post-order-instruction-section="formSelectedSectionData"
      :isEdit="isEditingPostOrderInstructionSection"
      @saved-section="(section) => selectSection(section.id!)"
    />

    <!-- Form Template Area -->
    <v-main
      flat
      id="sections-container"
      class="rounded-lg bg-background border-t border-b"
      style="overflow-y: scroll"
    >
      <v-card
        class="d-flex flex-column"
        tile
        variant="flat"
        height="100%"
        :class="isFieldDetailDrawerActive ? 'mr-n4' : ''"
      >
        <NoDataAvailablePlaceholder
          class="pt-6"
          v-if="instruction.sections.length == 0"
          width="400"
          header="No checkpoints added"
          header-class="font-weight-medium text-h5"
        >
          <template #description>
            <p class="mt-0 mb-6 text-caption text-medium-emphasis">
              <span> You do not have any sections for this post order instruction.</span>
            </p>
          </template>

          <template #primaryAction>
            <v-btn color="primary" depressed @click="openPostOrderInstructionSectionFormDialog()">
              <template #prepend>
                <v-icon left class="hidden-sm-and-down"> mdi-plus </v-icon>
              </template>
              Create Checkpoint
            </v-btn>
          </template>
        </NoDataAvailablePlaceholder>

        <template v-else-if="selectedSection">
          <PostOrderInstructionDetailSectionFieldCanvas
            ref="sectionCanvasRef"
            :is-section-vehicle-log="
              selectedSection.type == PostOrderInstructionSectionTypeEnum.VehicleLog
            "
          />
        </template>

        <template v-else-if="!selectedSection">
          <NoDataAvailablePlaceholder
            class="pt-16"
            width="400"
            header="No section selected"
            header-class="font-weight-medium text-h5"
          >
            <template #description>
              <p class="mt-0 mb-6 text-caption text-medium-emphasis">
                <span> Select a section from the left panel to view fields workflow </span>
              </p>
            </template>
          </NoDataAvailablePlaceholder>
        </template>
      </v-card>
    </v-main>
    <!-- Form Template Area -->

    <PostOrderInstructionDetailFieldDrawer
      v-if="selectedNode"
      v-model="isFieldDetailDrawerActive"
      :field="selectedNode.field"
    />
  </v-layout>
</template>

<script setup lang="ts">
import { computed, provide, ref, watch } from 'vue'

import type { ISystemError } from '@/models/error'
import type { IPostOrderNode } from '@/models/post-order/tree'
import {
  PostOrderInstructionSectionTypeEnum,
  type IPostOrderInstruction,
  type IPostOrderInstructionsSection,
  type IPostOrderInstructionsSectionData
} from '@/models/post-order'

import { PostOrderNodeSymbol, PostOrderSymbol } from '@/components/post-order/postOrderProvide'

import ErrorAlert from '../common/ErrorAlert.vue'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import PostOrderInstructionDetailFieldDrawer from '@/components/post-order/PostOrderInstructionDetailFieldDrawer.vue'
import PostOrderInstructionDetailSectionFieldCanvas from '@/components/post-order/PostOrderInstructionDetailSectionFieldCanvas.vue'
import PostOrderInstructionDetailSectionFormDialog from './PostOrderInstructionDetailSectionFormDialog.vue'
import PostOrderInstructionDetailSectionList from '@/components/post-order/PostOrderInstructionDetailSectionList.vue'

interface Props {
  instruction: IPostOrderInstruction
  postOrderId: number
}

const props = defineProps<Props>()

const postOrderId = computed(() => props.postOrderId)
const postOrderInstructionId = computed(() => props.instruction.id!)

const selectedSection = ref<IPostOrderInstructionsSection | null>(null)
const postOrderInstructionSectionId = computed(() => selectedSection.value?.id!)

const sectionCanvasRef = ref<typeof PostOrderInstructionDetailSectionFieldCanvas>()

const isDescriptionPreview = ref(false)
function toggleDescriptionPreview() {
  isDescriptionPreview.value = !isDescriptionPreview.value
}

provide(PostOrderSymbol, {
  postOrderId: postOrderId,
  instructionId: postOrderInstructionId,
  sectionId: postOrderInstructionSectionId
})

watch(
  () => props.instruction,
  (value) => {
    if (value && value.sections.length > 0) {
      if (selectedSection.value) {
        const isSelectedSectionInNewSectionList = value.sections.find(
          (section) => section.id == selectedSection.value!.id
        )

        if (!isSelectedSectionInNewSectionList) {
          const firstSection = value.sections[0]
          // Select first section if no sections currently selected
          selectSection(firstSection.id!)
        }
      } else {
        // If no section was selected then just select the first section in the list
        const firstSection = value.sections[0]

        if (firstSection) selectSection(firstSection.id!)
      }
    }
  },
  { immediate: true, deep: true }
)

function selectSection(sectionId: number) {
  const foundSection = props.instruction
    ? props.instruction.sections.find((postOrderSection) => postOrderSection.id == sectionId) ??
      null
    : null
  selectedSection.value = foundSection

  // pan to center
  sectionCanvasRef.value?.resetViewPort()
}

watch(selectedSection, () => {
  // Deselect any selected node
  selectNode()
})

// TREE NODE
const selectedNode = ref<IPostOrderNode | null>(null)

function selectNode(node?: IPostOrderNode) {
  selectedNode.value = node ?? null

  if (node) {
    isFieldDetailDrawerActive.value = true
  }
}

provide(PostOrderNodeSymbol, {
  selectedNode,
  selectNode
})

const error = ref<ISystemError | null>(null)

function clearErrors() {
  error.value = null
}

const isFieldDetailDrawerActive = ref(false)

const isPostOrderInstructionSectionFormDialogActive = ref(false)
const formSelectedSectionData = ref<IPostOrderInstructionsSectionData | null>(null)
const isEditingPostOrderInstructionSection = ref(false)

function openPostOrderInstructionSectionFormDialog(section?: IPostOrderInstructionsSection) {
  isEditingPostOrderInstructionSection.value = section ? true : false

  formSelectedSectionData.value = section
    ? section
    : {
        type: PostOrderInstructionSectionTypeEnum.Checkpoint,
        section: { order: props.instruction.sections.length + 1 }
      }

  isPostOrderInstructionSectionFormDialogActive.value = true
}
</script>

<style lang="scss">
.instruction-description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: initial;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  white-space: pre-line;
  opacity: var(--v-high-emphasis-opacity);
  max-height: 100px;

  transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.instruction-description-expanded {
  overflow: scroll !important;
  line-clamp: none !important;
  -webkit-line-clamp: none !important;
  display: flex;
  flex-direction: column;
  min-height: auto !important;
  height: auto !important;
  max-height: 100% !important;
}
</style>
