<template>
  <ErrorAlert
    v-if="error != null"
    dense
    :error="error"
    @clearErrors="clearErrors()"
    class="mb-0 mt-4 rounded"
  />

  <v-list-subheader
    class="vehicle-subheader d-inline-flex align-center font-weight-medium mb-2 px-6"
    v-if="currentUser?.hasPermission('fleet.view_vehiclemodel')"
  >
    <div class="text-subtitle-2 d-inline-flex align-center justify-start">
      <div class="me-2 align-self-center text-high-emphasis">Models</div>

      <v-card
        variant="tonal"
        width="24"
        height="24"
        style="display: inline-grid"
        class="align-center justify-center text-caption font-weight-bold"
      >
        <span v-if="!isPending">
          {{ vehicleModels?.length ?? 0 }}
        </span>
        <span v-else> 0 </span>
      </v-card>
    </div>
    <div class="ml-auto">
      <v-fade-transition mode="out-in">
        <v-progress-circular v-if="isPending" indeterminate color="primary" width="2" size="20" />
        <div v-else-if="currentUser.hasPermission('fleet.add_vehiclemodel')">
          <v-tooltip location="bottom" open-delay="450">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="action-btn rounded"
                variant="text"
                color="primary"
                size="32"
                icon
                density="comfortable"
                @click="openVehicleModelFormDialog()"
              >
                <v-icon size="18" icon="mdi-plus" />
              </v-btn>
            </template>
            Add Vehicle Model
          </v-tooltip>
        </div>
      </v-fade-transition>
    </div>
  </v-list-subheader>
  <v-expand-transition>
    <div v-show="!isPending">
      <div class="d-flex flex-column px-6">
        <template v-if="vehicleModels && vehicleModels.length > 0">
          <v-list class="vehicle-list px-0 rounded-lg mb-6" variant="flat" nav>
            <template v-for="(model, modelIndex) in vehicleModels" :key="modelIndex">
              <v-list-item>
                <v-list-item-title>{{ model.name }} </v-list-item-title>
                <template v-slot:append>
                  <VehicleModelDetailOptionsButton
                    :vehicle-model="model"
                    @vehicle-model-edit-pressed="
                      (vehicleModel: IVehicleModel) => openVehicleModelFormDialog(vehicleModel)
                    "
                    @vehicle-model-delete-pressed="
                      (vehicleModel: IVehicleModel) => openDeleteVehicleModelDialog(vehicleModel)
                    "
                  />
                </template>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-list>
        </template>
        <template v-else>
          <NoDataAvailablePlaceholder
            width="300"
            header="Add a vehicle model here"
            class="mt-0 mb-0"
          >
            <template #description>
              <p class="mt-0 mb-6 text-caption text-medium-emphasis">
                <span v-if="currentUser?.hasPermission('fleets.add_vehiclemodel')">
                  Looks like you haven't added any vehicle models yet. Get started by creating a new
                  vehicle model!
                </span>
                <span v-else>
                  It seems you currently don't have the necessary permissions to add a new vehicle
                  model. To request access or for further assistance, please contact your system
                  administrator.
                </span>
              </p>
            </template>

            <template #primaryAction v-if="currentUser?.hasPermission('fleets.add_vehiclemodel')">
              <v-btn @click="openVehicleModelFormDialog()">Add Vehicle Model</v-btn>
            </template>
          </NoDataAvailablePlaceholder>
        </template>

        <VehicleModelFormDialog
          v-model:dialog="isVehicleModelFormDialogActive"
          :vehicleModel="selectedVehicleModel"
          :isEdit="!!selectedVehicleModel"
          :vehicle-make-id="vehicleMakeId"
        />
      </div>
    </div>
  </v-expand-transition>

  <ConfirmationDialog
    v-if="selectedVehicleModel"
    width="500"
    title="Delete vehicle model"
    v-model="vehicleDeleteDialog"
    v-model:error="deleteError"
  >
    <template #message>
      Are you sure you want to delete this vehicle model?
      <br />
      <div class="pt-2">This action cannot be undone.</div>
    </template>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        variant="flat"
        :disabled="deleteInProgress"
        :loading="deleteInProgress"
        @click="removeVehicleModel(selectedVehicleModel!)"
      >
        Delete
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'
import type { IVehicleModel } from '@/models/fleet-management'
import { useFetchVehicleModels, useDeleteVehicleModel } from '@/composables/vehicle-model'
import { useQueryClient } from '@tanstack/vue-query'

import VehicleModelFormDialog from './VehicleModelFormDialog.vue'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import VehicleModelDetailOptionsButton from './VehicleModelDetailOptionsButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'

interface Props {
  vehicleMakeId: number
}

const props = withDefaults(defineProps<Props>(), {})

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const isVehicleModelFormDialogActive = ref(false)
const selectedVehicleModel = ref<IVehicleModel>()

const queryClient = useQueryClient()

const vehicleMakeId = ref(props.vehicleMakeId!)

function openVehicleModelFormDialog(vehicleModel?: IVehicleModel) {
  selectedVehicleModel.value = vehicleModel

  isVehicleModelFormDialogActive.value = true
}

function clearErrors() {
  error.value = null
}

const vehicleDeleteDialog = ref(false)

function openDeleteVehicleModelDialog(vehicle: IVehicleModel) {
  selectedVehicleModel.value = vehicle
  vehicleDeleteDialog.value = true
}

const {
  isPending: deleteInProgress,
  error: deleteError,
  mutate: deleteVehicleModel
} = useDeleteVehicleModel(vehicleMakeId)

function removeVehicleModel(vehicle: IVehicleModel) {
  deleteVehicleModel(vehicle.id!, {
    onSuccess: () => {
      closeDialog()
    }
  })
}

function closeDialog() {
  vehicleDeleteDialog.value = false
}

const { vehicleModels, isPending, error, queryKey } = useFetchVehicleModels(vehicleMakeId)
watch(
  () => props.vehicleMakeId,
  (newVehicleMakeId, oldVehicleMakeId) => {
    if (newVehicleMakeId && newVehicleMakeId != oldVehicleMakeId) {
      if (currentUser.value?.hasPermission('fleet.view_vehiclemodel')) {
        vehicleMakeId.value = newVehicleMakeId

        queryClient.invalidateQueries({ queryKey: queryKey.value })
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.vehicle-subheader .v-list-subheader__text {
  display: flex;
  width: 100%;
}

.vehicle-list .v-list-item--nav {
  padding-inline: 0px;
}
</style>
