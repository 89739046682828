import { FieldConstraintState, type IFormFieldOption } from '@/models/form'
import type {
  IPostOrderInstructionsField,
  IPostOrderInstructionsFieldOption
} from '../post-order.types'
import type { IPostOrderNode, IPostOrderNodeData } from './post-order-tree.types'

export class PostOrderNode implements IPostOrderNode {
  parent: IPostOrderNode | null
  field: IPostOrderInstructionsField
  option?: IPostOrderInstructionsFieldOption
  children: IPostOrderNode[] = []

  constructor(data: IPostOrderNodeData) {
    this.parent = data.parent ?? null
    this.field = data.field
    this.option = data.option
    this.children = data.children
  }

  /** If node is a leaf node */
  isLeaf(): boolean {
    return !this.hasChildren()
  }

  /**
   * If node is a leaf node
   * guarantee that option is not null
   */
  isOptionNode(): this is IPostOrderNode & {
    parent: IPostOrderNode
    option: IPostOrderInstructionsFieldOption
  } {
    return !!this.option
  }

  /**
   * If node falls under an option Node
   */
  isInOptionNodeBranch(): boolean {
    let isInNodeBranch = false

    this.traverseUpBranch((node, stop) => {
      if (node.isOptionNode()) {
        isInNodeBranch = true
        stop()
      }
    })

    return isInNodeBranch
  }

  /**
   * If node has a parent node,
   * guarantee that parent is not null
   */
  hasParent(): this is PostOrderNode & { parent: IPostOrderNode } {
    return !!this.parent
  }

  /** If nodes has any children */
  hasChildren(): boolean {
    return this.children.length > 0
  }

  /** If any of the children are option nodes */
  hasOptionNodeChildren(): boolean {
    return this.hasChildren() && this.children.some((node) => !!node.option)
  }

  /**
   * Insert a child node into this node
   *
   * If node has any children that are option nodes then just add the newly inserted
   * to the list of children
   * Else then update the children of the current node to be the children of the child node
   *
   */
  insertChild(data: IPostOrderNodeData): IPostOrderNode {
    // create a new node from data
    const newNode = new PostOrderNode({ ...data, parent: this })

    if (this.hasOptionNodeChildren()) {
      if (newNode.isOptionNode()) {
        this.children.push(newNode)
      }
    } else {
      // store children of current node
      const children = this.children

      // clear children of current node
      this.children = []

      // set newly created node and current node child
      this.children.push(newNode)

      // set previous children of current to child's children
      newNode.children = children
    }

    // update order of all children from this node
    newNode.updateOrder(this.field.field.order + 1)

    return newNode
  }

  /**
   * Remove a child node from the parent's children array.
   */
  removeChild(node: IPostOrderNode): IPostOrderNode | null {
    const index = this.children.indexOf(node)
    let foundNode = null

    if (index !== -1) {
      foundNode = this.children[index]
      this.children.splice(index, 1)
    }

    return foundNode
  }

  /**
   * Update the order of each child node,
   * skipping option nodes.
   */
  updateOrder(startingOrder: number = this.field.field.order): number {
    let currentOrder = startingOrder

    // Only update order if the node is not an option node
    if (!this.isOptionNode()) {
      this.field.field.order = currentOrder
      currentOrder++
    }

    // Recursively update order for children
    for (const child of this.children) {
      currentOrder = child.updateOrder(currentOrder)
    }

    return currentOrder // Return the last used order
  }

  /**
   * Check if a specific field is in the same branch as this node.
   */
  isFieldInSameBranch(targetField: IPostOrderInstructionsField): boolean {
    let currentNode: IPostOrderNode | null = this

    // Traverse upwards from the current node to the root
    while (currentNode !== null) {
      if (currentNode.field === targetField) {
        return true
      }
      currentNode = currentNode.parent
    }

    return false
  }

  shouldFieldBeInTheInSameBranch(targetField: IPostOrderInstructionsField): boolean {
    // if this field has no constraints then field is a part added to branch
    if (targetField.constraints.length == 0) return true

    const passedOptionNodes: boolean[] = []

    // Traverse the branch and for each node check that any of the targetField constraint condition
    // match any of the nodes values if any of the node are options
    this.traverseUpBranch((node) => {
      // Only check the option nodes to see that the new field is a part of the branch
      if (node.isOptionNode()) {
        passedOptionNodes.push(
          targetField.constraints.some((constraint) => {
            if (constraint.constraint.state !== FieldConstraintState.Required) return false
            return constraint.conditions.some((condition) => {
              if ((condition.condition.option as IFormFieldOption).id == node.option.option.id) {
                return true
              } else {
                return false
              }
            })
          })
        )
      }
    })

    return passedOptionNodes.every((value) => value == true)
  }

  /**
   * Traverse up the tree from this node to the root, performing a callback at each node.
   * The callback receives the current node and a stop function.
   */
  traverseUpBranch(callback: (node: IPostOrderNode, stop: () => void) => void): IPostOrderNode[] {
    const nodes: IPostOrderNode[] = []
    let currentNode: IPostOrderNode | null = this

    const stopTraversal = { stop: false }

    const stop = () => {
      stopTraversal.stop = true
    }

    while (currentNode !== null && !stopTraversal.stop) {
      // Callback operation on the current node
      callback(currentNode, stop)

      // Get the current node
      nodes.push(currentNode)

      // Move to the parent node
      currentNode = currentNode.parent
    }

    return nodes
  }

  /**
   * Traverse down the tree from this node using depth first search to the root, performing a callback at each node.
   * The callback receives the current node and a stop function.
   */
  traverseDownBranch(callback: (node: IPostOrderNode, stop: () => void) => void): IPostOrderNode[] {
    const nodes: IPostOrderNode[] = []
    let stopTraversal = false

    const stop = () => {
      stopTraversal = true
    }

    const depthFirstSearch = (node: IPostOrderNode) => {
      if (stopTraversal) return // Stop traversal if flag is set

      // Perform the callback operation on the current node
      callback(node, stop)
      nodes.push(node)

      // Traverse each child node if traversal hasn't been stopped
      for (const child of node.children) {
        if (!stopTraversal) {
          depthFirstSearch(child)
        }
      }
    }

    depthFirstSearch(this) // Start from the current node
    return nodes
  }

  toString(): string {
    return this.isOptionNode() ? this.option.toString() : this.field.toString()
  }
}
