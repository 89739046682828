<template>
  <div class="treeview">
    <PostOrderFieldTreeViewChildren
      :node="tree"
      :is-vehicle-log-node="isVehicleLogTree"
      :level="1"
      :coordinate="{ x: 600, y: 100 }"
      @add-field-node="openCreateFieldDialogFromNode"
      @add-option-node="openCreateFieldOptionDialogFromNode"
      @delete-node="openDeleteFieldDialogFromNode"
    >
    </PostOrderFieldTreeViewChildren>
    <!-- coordinate set to a default that is in the center of the canvas -->
  </div>

  <PostOrderInstructionDetailFieldOptionFormDialog
    v-if="selectedFormNode && selectedFormNode.field.id!"
    v-model:dialog="isCreateFieldOptionDialogActive"
    :option="{
      option: {
        value: `Option ${selectedFormNode.field.options.length + 1}`,
        order: selectedFormNode.field.options.length + 1
      },
      observation_text: '',
      incident_level: ReportActivityStatusEnum.SecurityLevel1
    }"
    :is-edit="false"
    :post-order-field-id="selectedFormNode.field.id!"
  />

  <PostOrderInstructionDetailSectionFieldInputTypeDialog
    :width="864"
    :field="selectedFormNode?.field"
    v-model:dialog="isCreateFieldDialogActive"
    @selected-type="createNodeFromFieldType"
  />

  <ConfirmationDialog
    width="auto"
    :title="`Delete ${selectedFormNode?.isOptionNode() ? 'option' : 'field'}`"
    v-model="isDeleteNodeDialogActive"
    v-model:error="deleteNodeError"
  >
    <template #message>
      Are you sure you want to delete
      <span class="text-medium-high-emphasis font-weight-bold">{{
        selectedFormNode?.toString()
      }}</span
      >?
      <br />
      <div class="pt-2" v-if="selectedFormNode?.isLeaf()">This action cannot be undone.</div>
      <div class="pt-2" v-else>
        This action would remove this field and all the children fields. This cannot be undone.
      </div>
      .
    </template>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn variant="flat" :disabled="deleteNodeInProgress" @click="closeDeleteFieldDialog()">
        Cancel
      </v-btn>
      <v-btn
        color="error"
        variant="flat"
        :loading="deleteNodeInProgress"
        @click="deleteNode(selectedFormNode!)"
      >
        Delete
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, ref, watch } from 'vue'
import { SVG, type StrokeData, type Text as SVGDrawElement } from '@svgdotjs/svg.js'

import {
  PostOrderInstructionsField,
  PostOrderInstructionsFieldConstraint,
  PostOrderInstructionsFieldConstraintCondition,
  type IPostOrderInstructionsField,
  type IPostOrderInstructionsFieldConstraintConditionData,
  type IPostOrderInstructionsFieldConstraintData,
  type IPostOrderInstructionsFieldData,
  type IPostOrderInstructionsFieldOption
} from '@/models/post-order'
import { PostOrderNode, type IPostOrderNode } from '@/models/post-order/tree'
import {
  FieldConstraintState,
  FormField,
  FormFieldTypeEnum,
  type IFormFieldOption
} from '@/models/form'

import { cloneDeep } from 'lodash'
import { useQueryClient } from '@tanstack/vue-query'
import {
  useCreatePostOrderInstructionsField,
  useCreatePostOrderInstructionsFieldConstraint,
  useCreatePostOrderInstructionsFieldConstraintCondition,
  useDeletePostOrderInstructionsField,
  useDeletePostOrderInstructionsFieldOption,
  useFetchPostOrderInstructionsFields,
  useUpdatePostOrderInstructionsField
} from '@/composables/post-order'

import { SystemError, type ISystemError } from '@/models/error'
import { ReportActivityStatusEnum } from '@/models/report'
import { getTarget } from '@/utils/helpers'
import { PostOrderNodeSymbol, PostOrderSymbol } from '../postOrderProvide'

import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import PostOrderFieldTreeViewChildren from './PostOrderFieldTreeViewChildren.vue'
import PostOrderInstructionDetailFieldOptionFormDialog from '../PostOrderInstructionDetailFieldOptionFormDialog.vue'
import PostOrderInstructionDetailSectionFieldInputTypeDialog from './PostOrderInstructionDetailSectionFieldInputTypeDialog.vue'

interface Props {
  fields: IPostOrderInstructionsField[]
  isVehicleLogTree: boolean
}

const props = defineProps<Props>()

interface Emits {
  (name: 'add-field', field?: IPostOrderInstructionsField): void
}

const emit = defineEmits<Emits>()

const tree = ref(initTree(cloneDeep(props.fields)))

watch(
  () => props.fields,
  (value) => {
    const treeValue = initTree(cloneDeep(value))

    tree.value = treeValue

    selectNodeOnTreeUpdate(treeValue)
  },
  { deep: true }
)

function initTree(fields: IPostOrderInstructionsField[]): IPostOrderNode {
  if (fields.length > 0) {
    return constructTree(fields)
  } else {
    const defaultField = new PostOrderInstructionsField({
      field: {
        id: 0,
        label: 'Click below to add an input field.',
        type: FormFieldTypeEnum.Label,
        order: 1,
        config: {}
      }
    })
    return createNodeFromField(defaultField)
  }
}

/* Creates a tree from a list of post order instruction sections fields */
function constructTree(fields: IPostOrderInstructionsField[]): IPostOrderNode {
  // # Step 1: Find the root field (field with the lowest order value)
  const rootField = findLowestOrderField(fields)!

  // # Step 2: Create a dictionary to map field IDs to their corresponding field objects for easy lookup
  const fieldMap = createFieldMap(fields)

  // # Step 3: Initialize the root node
  const rootNode = createNodeFromField(rootField)

  // # Step 4: Start adding children from the root node
  addNodeChildren(rootNode, fieldMap)

  return rootNode
}

/**
 *  Create a recursive function to add children to nodes
 * @param node
 * @param fieldMap
 */
function addNodeChildren(
  node: IPostOrderNode,
  fieldMap: Map<number, IPostOrderInstructionsField>
): void {
  if (!node.isOptionNode() && !node.hasChildren()) {
    // # Check if the field has options
    if (fieldMap.has(node.field.id!) && fieldMap.get(node.field.id!)!.options.length > 0) {
      for (const option of fieldMap.get(node.field.id!)!.options) {
        const optionNode = createNodeFromOption(option, node)

        node.children.push(optionNode)
        addNodeChildren(optionNode, fieldMap)
      }
    } else {
      // # Find the next field based on order in current branch
      const nextField = findNextFieldInBranch(fieldMap, node)

      if (nextField) {
        const childNode = createNodeFromField(nextField, node)

        node.children.push(childNode)
        addNodeChildren(childNode, fieldMap)
      }
    }
  } else if (node.isOptionNode()) {
    // # Find the next field based on order in current branch
    let nextField = findNextFieldInBranch(fieldMap, node)

    if (nextField) {
      const childNode = createNodeFromField(nextField, node)

      node.children.push(childNode)
      addNodeChildren(childNode, fieldMap)
    }
  }
}

// # Helper Functions

/* To identify the root node of the tree by finding the field with the lowest order value. */
function findLowestOrderField(
  fields: IPostOrderInstructionsField[]
): IPostOrderInstructionsField | null {
  let minOrder = 1
  let rootField = null
  for (const field of fields) {
    if (field.field.order <= minOrder) {
      minOrder = field.field.order
      rootField = field
    }
  }
  return rootField
}

/* To create a mapping (dictionary) of field IDs to their corresponding field objects for quick lookups. */
function createFieldMap(
  fields: IPostOrderInstructionsField[]
): Map<number, IPostOrderInstructionsField> {
  const fieldMap = new Map()
  for (const field of fields) {
    fieldMap.set(field.id, field)
  }
  return fieldMap
}

/* To create a new node representing a field in the tree structure. */
function createNodeFromField(
  field: IPostOrderInstructionsField,
  parentNode: IPostOrderNode | null = null
): IPostOrderNode {
  return new PostOrderNode({
    parent: parentNode,
    field: field,
    children: []
  })
}

/* To create a new node representing an option in the tree structure. */
function createNodeFromOption(
  option: IPostOrderInstructionsFieldOption,
  parentNode: IPostOrderNode
): IPostOrderNode {
  return new PostOrderNode({
    parent: parentNode,
    option: option,
    field: parentNode.field,
    children: []
  })
}

/* To find the next field based on order after the current node's order. In the current branch */
function findNextFieldInBranch(
  fieldMap: Map<number, IPostOrderInstructionsField>,
  node: IPostOrderNode
): IPostOrderInstructionsField | null {
  // ensuring that the first field encountered with a valid order will always have a smaller value
  const currentInitialOrder = node.field.field.order
  let minOrder = Array.from(fieldMap.entries()).length + 1
  let nextField: IPostOrderInstructionsField | null = null
  for (const field of fieldMap.values()) {
    if (field.field.order > currentInitialOrder && field.field.order < minOrder) {
      // also make sure the expected found field node is in the same node
      if (node.shouldFieldBeInTheInSameBranch(field)) {
        minOrder = field.field.order
        nextField = field
      }
    }
  }
  return nextField
}

// Node Creation
const selectedFormNode = ref<IPostOrderNode | null>(null)
const isCreateFieldDialogActive = ref(false)

function openCreateFieldDialogFromNode(node: IPostOrderNode) {
  isCreateFieldDialogActive.value = true

  selectedFormNode.value = node
}

const isCreateFieldOptionDialogActive = ref(false)
function openCreateFieldOptionDialogFromNode(node: IPostOrderNode) {
  selectedFormNode.value = node

  // !!!Hack!!!
  // Rendering the form dialog immediatly after the selectedFormNode is set will not set the prop in the component
  nextTick(() => {
    isCreateFieldOptionDialogActive.value = true
  })
}

const postOrderContext = inject(PostOrderSymbol)

if (!postOrderContext) throw new Error('[Post Order] Could not find injected post order context')

const useCreateFieldMutation = useCreatePostOrderInstructionsField(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const useUpdateFieldMutation = useUpdatePostOrderInstructionsField(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const useCreateFieldConstraintMutation = useCreatePostOrderInstructionsFieldConstraint(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)
const useCreateFieldConstraintConditionMutation =
  useCreatePostOrderInstructionsFieldConstraintCondition(
    postOrderContext.postOrderId,
    postOrderContext.instructionId,
    postOrderContext.sectionId
  )

const useFetchPostOrderInstructionFieldsQuery = useFetchPostOrderInstructionsFields(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const queryClient = useQueryClient()

const postOrderNodeContext = inject(PostOrderNodeSymbol)

if (!postOrderNodeContext)
  throw new Error('[Post Order Node] Could not find injected post order node context')

async function createNodeFromFieldType(type: FormFieldTypeEnum) {
  const node = selectedFormNode.value!
  // determine the first node is an actual saved node

  // if root node is not saved in the server
  const isFirstProxyNode = !node.hasParent() && (!node.field.id || !node.isOptionNode())

  // construct the new order for this field
  const newOrder = isFirstProxyNode ? 1 : node.field.field.order + 1

  // traverse the tree up and determine if any of the fields are an option and add a constraint to that option
  let constraints = node.field.constraints

  if (node.isOptionNode()) {
    // add the current option to the list of constraints to create if creating a child of an option node
    const postOrderConstraint = new PostOrderInstructionsFieldConstraint({
      constraint: {
        state: FieldConstraintState.Required
      },
      conditions: [
        new PostOrderInstructionsFieldConstraintCondition({
          condition: {
            option: node.option.option.id
          }
        })
      ]
    })

    constraints.push(postOrderConstraint)
  }

  const postOrderFieldData = {
    field: new FormField({
      label: 'Untitled',
      type: type,
      order: newOrder,
      config: { disabled: node.isInOptionNodeBranch() }
    }),

    constraints: [...constraints]
  }

  // synchronously create node on server
  const createdFieldData = await useCreateFieldMutation.mutateAsync(postOrderFieldData)

  const createdField = new PostOrderInstructionsField(createdFieldData)

  // list for mapping each create constraint to
  const createdConstraintsDataList: IPostOrderInstructionsFieldConstraintData[] = []

  // then from the parent node's constraints create a constraint for the created node below and the same for each constraints option
  try {
    for (let constraintIndex = 0; constraintIndex < constraints.length; constraintIndex++) {
      const nodePostOrderConstraint = constraints[constraintIndex]
      const postOrderConstraintData: IPostOrderInstructionsFieldConstraintData = {
        constraint: {
          state: nodePostOrderConstraint.constraint.state
        }
      }

      // asynchronously create node constraints and options on server if any are to be added
      const createdFieldConstraintData = await useCreateFieldConstraintMutation.mutateAsync({
        fieldId: createdField.id!,
        constraint: postOrderConstraintData
      })
      createdConstraintsDataList.push(createdFieldConstraintData)

      const createdConstraint = new PostOrderInstructionsFieldConstraint(createdFieldConstraintData)

      for (let index = 0; index < nodePostOrderConstraint.conditions.length; index++) {
        const nodePostOrderConstraintCondition = nodePostOrderConstraint.conditions[index]
        const postOrderConstraintConditionData: IPostOrderInstructionsFieldConstraintConditionData =
          {
            condition: {
              // incase creating from an option node locally the expected option is no longer an object but a number
              option:
                (nodePostOrderConstraintCondition.condition.option as IFormFieldOption).id! ??
                nodePostOrderConstraintCondition.condition.option
            }
          }
        const createdConditionData = await useCreateFieldConstraintConditionMutation.mutateAsync({
          fieldId: createdField.id!,
          constraintId: createdConstraint.id!,
          condition: postOrderConstraintConditionData
        })

        // Update the post order field data lst to update node with
        createdConstraintsDataList[constraintIndex].conditions!.push(createdConditionData)
      }
    }

    // Update created field constraint with new constraint added for each level
    createdField.constraints.push(
      ...createdConstraintsDataList.map(
        (constraint) => new PostOrderInstructionsFieldConstraint(constraint)
      )
    )

    // Create node from update field data
    const createdNode = createNodeFromField(createdField, node)

    // place created node as a child for the current node we are adding from
    node.insertChild(createdNode)

    if (node.field.field.id) {
      // for each updated node by its order we have to update the order in the api
      await updateNodeOrder(createdNode, createdNode.field.field.order) //minus to update order to newOrder
    }

    // after creating and constraint and  conditions for those constraints invalidate the query and wait for it to finish
    await queryClient.invalidateQueries({
      queryKey: useFetchPostOrderInstructionFieldsQuery.queryKey
    })

    selectedFormNode.value = node
    postOrderNodeContext?.selectNode(createdNode)

    emit('add-field')
  } catch (error) {
    console.error(error)
  }
}

function selectNodeOnTreeUpdate(tree: IPostOrderNode) {
  const selectedNode = postOrderNodeContext?.selectedNode.value
  if (selectedNode) {
    tree.traverseDownBranch((node, stop) => {
      if (
        node.isOptionNode() && selectedNode.isOptionNode()
          ? selectedNode.option.id === node.option.id
          : selectedNode.field.id === node.field.id
      ) {
        postOrderNodeContext?.selectNode(node)
        stop()
      }
    })
  }
}

/**
 * Update the order of each child node,
 * skipping option nodes.
 */
async function updateNodeOrder(node: IPostOrderNode, startingOrder = node.field.field.order) {
  let currentOrder = startingOrder

  // Only update order if the node is not an option node
  if (!node.isOptionNode()) {
    node.field.field.order = currentOrder

    const postOrderFieldData: IPostOrderInstructionsFieldData = {
      id: node.field.id,
      field: { id: node.field.field.id!, order: currentOrder }
    }

    try {
      // synchronously update node on server
      const updatedPostOrderField = new PostOrderInstructionsField(
        await useUpdateFieldMutation.mutateAsync(postOrderFieldData)
      )

      node.field = updatedPostOrderField
      currentOrder++
    } catch (error) {
      return currentOrder
    }
  }

  // Recursively update order for children
  for (const child of node.children) {
    currentOrder = await updateNodeOrder(child, currentOrder)
  }

  return currentOrder // Return the last used order
}

/**
 * Delete mutation composable for api callback to remove post order field
 */
const useDeleteFieldMutation = useDeletePostOrderInstructionsField(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)
/**
 * Delete mutation composable for api callback to remove post order field option
 */
const useDeleteFieldOptionMutation = useDeletePostOrderInstructionsFieldOption(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const deleteNodeInProgress = computed(
  () => useDeleteFieldOptionMutation.isPending.value || useDeleteFieldMutation.isPending.value
)

const isDeleteNodeDialogActive = ref<boolean>(false)
const deleteNodeError = ref<ISystemError | null>(null)

function openDeleteFieldDialogFromNode(node: IPostOrderNode) {
  isDeleteNodeDialogActive.value = true

  selectedFormNode.value = node
}

function closeDeleteFieldDialog() {
  isDeleteNodeDialogActive.value = false

  selectedFormNode.value = null
}

/**
 * Delete this node and all its descendants.
 * If this node has a parent, it should be removed from the parent's children.
 */
async function deleteNode(node: IPostOrderNode) {
  await deleteNodeBranch(node)

  // after deleting the branch invalidate the query and wait for it to finish
  await queryClient.invalidateQueries({
    queryKey: useFetchPostOrderInstructionFieldsQuery.queryKey
  })

  postOrderNodeContext?.selectNode()

  // close delete confirmation dialog
  closeDeleteFieldDialog()
}

/**
 * Remove a child node and its entire subtree (i.e., all its descendants).
 * If the node itself is to be deleted, this method should be called on its parent.
 */
async function deleteNodeBranch(node: IPostOrderNode) {
  async function deleteFieldFromNode(node: IPostOrderNode) {
    try {
      if (node.isOptionNode()) {
        // call delete field option instead of the option node's parent
        await useDeleteFieldOptionMutation.mutateAsync({
          fieldId: node.field.id!,
          optionId: node.option.id!
        })
      } else {
        await useDeleteFieldMutation.mutateAsync(node.field.id!)
      }
    } catch (error) {
      if (error instanceof SystemError) {
        deleteNodeError.value = error
      }
    }
  }

  // First, remove all leaf nodes
  for (let i = node.children.length - 1; i >= 0; i--) {
    const child = node.children[i]
    if (child.isLeaf()) {
      await deleteFieldFromNode(child)
      node.removeChild(child)
    } else {
      // Recursively handle non-leaf nodes
      await deleteNodeBranch(child)
    }
  }

  // After removing leaves, check if this node has become a leaf and should be removed
  if (node.isLeaf()) {
    await deleteFieldFromNode(node)
    if (node.hasParent()) {
      node.parent.removeChild(node)
    }
  }
}

// LINES
const draw = ref<SVGDrawElement | null>(null)
function connectLinesToChildren(node: IPostOrderNode) {
  if (!draw.value) {
    draw.value = SVG('.workflow-canvas-svg') as SVGDrawElement //.size('100%', '100%')
    draw.value.clear()
  }

  const strokeStyle: StrokeData = { width: 1, color: '#46494d' }

  // Connect node card to node option button
  const nodeElement = getTarget(
    `#treeview-node-${node.isOptionNode() ? 'option-' + node.option.id : 'field-' + node.field.id}`
  )!

  const cardParentPosition = getNodeElementCenterPosition(nodeElement)

  const nodeElementButton = getTarget(
    `#treeview-node-${node.isOptionNode() ? 'option-' + node.option.id : 'field-' + node.field.id}-button`
  )

  let parentPosition = getNodeElementCenterPosition(nodeElement)

  // If there is a button below the first node then draw a line to connect to the first node
  if (nodeElementButton) {
    parentPosition = getNodeElementCenterPosition(nodeElementButton)
    // draw line from the parent node to the button
    draw.value
      .path(
        `M ${cardParentPosition.x} ${cardParentPosition.y} L ${parentPosition.x} ${parentPosition.y}`
      )
      .stroke(strokeStyle)
      .fill('none')
  }

  for (let index = 0; index < node.children.length; index++) {
    const childNode = node.children[index]

    const childElement =
      getTarget(
        `#treeview-node-${childNode.isOptionNode() ? 'option-' + childNode.option.id : 'field-' + childNode.field.id}`
      ) ||
      getTarget(
        `#treeview-node-${childNode.isOptionNode() ? 'option-' + childNode.option.id : 'field-' + childNode.field.id}-button`
      )

    const childPosition = getNodeElementCenterPosition(childElement!)
    // Draw a line from the parent to the child

    // Check if the current node has multiple children
    const hasMultipleChildren = node.children.length > 1

    // Determine the relative position of the child
    const isLeft = childPosition.x < parentPosition.x
    const isRight = childPosition.x > parentPosition.x

    let pathData = ''

    // if not extreme ends then just use a vertical line

    if (hasMultipleChildren && (isLeft || isRight)) {
      // Define the radius for the rounded corner
      const radius = 8

      // Determine if child is at extreme end
      const isExtremeLeft = isLeft && index == 0
      const isExtremeRight = isRight && index == node.children.length - 1

      // For multiple children at extreme left/right positions, draw a curved line
      if (isExtremeLeft || isExtremeRight) {
        // Path with a right-angled curve with a radius of 8px
        pathData = `
          M ${parentPosition.x} ${parentPosition.y}
          L ${childPosition.x + (isLeft ? radius : -radius)} ${parentPosition.y}
          L ${childPosition.x} ${parentPosition.y + radius}
          L ${childPosition.x} ${childPosition.y}`
      } else {
        // For single children or left/right positions, draw a straight line
        pathData = `M ${childPosition.x} ${parentPosition.y} L ${childPosition.x} ${childPosition.y}`
      }
    } else {
      // For single children or non-left/right positions, draw a straight line
      pathData = `M ${parentPosition.x} ${parentPosition.y} L ${childPosition.x} ${childPosition.y}`
    }

    draw.value.path(pathData).stroke(strokeStyle).fill('none')

    connectLinesToChildren(childNode)
  }
}

function getNodeElementCenterPosition(nodeElement: Element) {
  const parentContainerElement = getTarget(
    '.workflow-canvas-svg-container'
  )!.getBoundingClientRect()

  const nodeRect = nodeElement!.getBoundingClientRect()
  const relativePosition: DOMRect = {
    bottom: nodeRect.bottom - parentContainerElement.bottom,
    left: nodeRect.left - parentContainerElement.left,
    right: nodeRect.right - parentContainerElement.right,
    // 10 is the offset for the padding of the button
    top: nodeRect.top - parentContainerElement.top + 10,
    toJSON: function () {
      return nodeRect.toJSON()
    },
    height: nodeRect.height,
    width: nodeRect.width,
    x: nodeRect.x - parentContainerElement.x,
    y: nodeRect.y - parentContainerElement.y
  }

  return {
    x: relativePosition.left + relativePosition.width / 2,
    y: relativePosition.top + relativePosition.height / 2
  }
}

watch(tree, (value) => nextTick(() => connectLinesToChildren(value)), {
  deep: true
})

function renderConnectionLines() {
  draw.value?.clear()
  connectLinesToChildren(tree.value)
}

defineExpose({
  renderConnectionLines
})
</script>
