<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Post Order Instruction Form Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mt-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form @submit.prevent ref="form" v-model="isDetailsValid" validate-on="submit lazy">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-text-field
              v-model="postOrderInstructionData.template!.title"
              label="Title*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['template'] as string[])"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-select
              disabled
              v-model="postOrderInstructionData.type"
              label="Type*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['type'] as string[])"
              :items="[
                { title: 'Start of Shift', value: PostOrderInstructionTypeEnum.Start },
                { title: 'Patrol', value: PostOrderInstructionTypeEnum.Patrol },
                { title: 'End of Shift', value: PostOrderInstructionTypeEnum.End }
              ]"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="postOrderInstructionData.template!.description"
              label="Description*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['template'] as string[])"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { ISystemError } from '@/models/error'
import type { VForm } from 'vuetify/components'

import { diffDeep } from '@/utils/helpers'
import { cloneDeep } from 'lodash'
import { requiredValidator } from '@/utils/validators'

import {
  useCreatePostOrderInstruction,
  useUpdatePostOrderInstruction
} from '@/composables/post-order'

import {
  PostOrderInstruction,
  PostOrderInstructionTypeEnum,
  type IPostOrderInstruction,
  type IPostOrderInstructionData
} from '@/models/post-order'

import AlFormCard from '../common/AlFormCard.vue'
import ErrorAlert from '../common/ErrorAlert.vue'

interface Props {
  postOrderId: number
  postOrderInstruction?: IPostOrderInstructionData
  isEdit: boolean
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

const postOrderInstructionData = ref<IPostOrderInstructionData>({ template: {} })

watch(dialog, (value) => {
  if (value) {
    clearErrors()

    postOrderInstructionData.value = !props.postOrderInstruction
      ? ({ template: {} } as IPostOrderInstructionData)
      : cloneDeep(props.postOrderInstruction)
  }
})

const form = ref<VForm>()
const isDetailsValid = ref(false)

const subtitle = computed(() => `${props.isEdit ? 'Update' : 'Create'} form template information`)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const postOrderId = computed(() => props.postOrderId)
const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updatePostOrderInstruction,
  reset: updateReset
} = useUpdatePostOrderInstruction(postOrderId)
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createPostOrderInstruction,
  reset: createReset
} = useCreatePostOrderInstruction(postOrderId)

const localError = ref<ISystemError | null>(null)

const loading = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = computed({
  get: () => {
    return localError.value || props.isEdit ? updateError.value : createError.value
  },
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  props.isEdit ? updateReset() : createReset()
  error.value = null
}

async function save() {
  clearErrors()

  const { valid } = await form.value!.validate()

  if (valid) {
    let postOrderInstructionInstance: IPostOrderInstruction | null = null

    try {
      postOrderInstructionInstance = new PostOrderInstruction(postOrderInstructionData.value)
    } catch (e: any) {
      // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
      error.value = e
    }

    if (postOrderInstructionInstance != null) {
      const saveAction = props.isEdit ? updatePostOrderInstruction : createPostOrderInstruction

      let payload = postOrderInstructionInstance
      if (props.isEdit) {
        payload = diffDeep(postOrderInstructionInstance, props.postOrderInstruction, true)
      }

      saveAction(payload, {
        onSuccess: () => {
          closeDialog()
        }
      })
    }
  }
}
</script>
