import { computed, ref, toRef, type MaybeRef } from 'vue'

import { VehicleMake, type IVehicleMakeData } from '@/models/fleet-management/vehicle'

import vehicleMakeService from '@/services/fleet_management/vehicle-make/vehicle-make'

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

export function useFetchVehicleMakes() {
  const queryKey = ref(['vehicle-makes'])

  const count = ref<number>(0)

  const {
    isLoading,
    isError,
    error,
    data: vehicleMakes
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => vehicleMakeService.fetchVehicleMakes(),

    select: (data) => data.map((vehicleMake) => new VehicleMake(vehicleMake))
  })

  return { queryKey, vehicleMakes, count, error, isError, isLoading }
}

export function useFetchVehicleMake(id: MaybeRef<number>) {
  const vehicleMakeIdRef = toRef(id)

  const queryKey = ref(['vehicle-make', vehicleMakeIdRef])

  const {
    data: vehicleMake,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(vehicleMakeIdRef.value)),
    queryFn: () => vehicleMakeService.fetchVehicleMake(vehicleMakeIdRef.value),
    select: (data) => new VehicleMake(data)
  })

  return { vehicleMake, isPending, error, queryKey }
}

export function useCreateVehicleMake() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-vehicle-make'],
    mutationFn: (vehicleMake: IVehicleMakeData) =>
      vehicleMakeService.createVehicleMake(vehicleMake),

    onSuccess: (data) => {
      queryClient.setQueryData(['vehicle-make', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-makes']) })
      snackbarStore.showSnackbar('VehicleMake created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateVehicleMake() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-vehicle-make'],
    mutationFn: (vehicleMake: IVehicleMakeData) =>
      vehicleMakeService.updateVehicleMake(vehicleMake),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-make', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-makes']) })
      snackbarStore.showSnackbar('VehicleMake updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteVehicleMake() {
  const mutationKey = ref(['delete-vehicle-make'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => vehicleMakeService.deleteVehicleMake(id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle-makes']) })
      snackbarStore.showSnackbar('VehicleMake deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}
