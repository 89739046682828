import type { IPostOrderNode } from '@/models/post-order/tree'
import type { InjectionKey, Ref } from 'vue'

export interface PostOrderProvideProps {
  postOrderId: Ref<number>
  instructionId: Ref<number>
  sectionId: Ref<number>
}
export const PostOrderSymbol: InjectionKey<PostOrderProvideProps> = Symbol.for('post-order:props')

export interface PostOrderNodeProps {
  selectedNode: Ref<IPostOrderNode | null>
  selectNode: (node?: IPostOrderNode) => void
}
export const PostOrderNodeSymbol: InjectionKey<PostOrderNodeProps> = Symbol.for('post-order:node')

// FULLSCREEN
export interface PostOrderFullscreenProps {
  isFullscreen: Ref<boolean>
  setFullscreen: (value: boolean) => void
}
export const PostOrderFullscreenSymbol: InjectionKey<PostOrderFullscreenProps> =
  Symbol.for('post-order:fullscreen')
