import { computed, ref, toRef, type MaybeRef } from 'vue'

import { Vehicle, type IVehicleData } from '@/models/fleet-management/vehicle'

import vehicleService from '@/services/fleet_management/vehicle/vehicle'

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

export function useFetchVehicles() {
  const queryKey = ref(['vehicles'])

  const {
    isLoading,
    isError,
    error,
    data: vehicles
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => vehicleService.fetchVehicles(),

    select: (data) => data.map((vehicle) => new Vehicle(vehicle))
  })

  return { queryKey, vehicles, error, isError, isLoading }
}

export function useFetchVehicle(id: MaybeRef<number>) {
  const vehicleIdRef = toRef(id)

  const queryKey = ref(['vehicle', vehicleIdRef])

  const {
    data: vehicle,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(vehicleIdRef.value)),
    queryFn: () => vehicleService.fetchVehicle(vehicleIdRef.value),
    select: (data) => new Vehicle(data)
  })

  return { vehicle, isPending, error, queryKey }
}

export function useCreateVehicle() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-vehicle'],
    mutationFn: (vehicle: IVehicleData) => vehicleService.createVehicle(vehicle),

    onSuccess: (data) => {
      queryClient.setQueryData(['vehicle', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['vehicles']) })
      snackbarStore.showSnackbar('Vehicle created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateVehicle() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-vehicle'],
    mutationFn: (vehicle: IVehicleData) => vehicleService.updateVehicle(vehicle),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicle', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['vehicles']) })
      snackbarStore.showSnackbar('Vehicle updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteVehicle() {
  const mutationKey = ref(['delete-vehicle'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => vehicleService.deleteVehicle(id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['vehicles']) })
      snackbarStore.showSnackbar('Vehicle deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}
