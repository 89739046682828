<template>
  <v-data-table
    :search="search"
    :items-per-page="25"
    :items-per-page-options="[{ value: 25, title: '25' }]"
    :hover="vehicles.length > 0"
    item-value="name"
    :headers="vehicleDataTableHeaders"
    :items="vehicles"
    :loading="loading"
    color="primary"
    sticky
    fixed-header
    density="default"
    height="calc(100% - 37px)"
    style="height: calc(100% - 93px); width: 100%"
    :row-props="generateRowProps"
  >
    <template #[`item.index`]="{ index }">
      {{ index + 1 }}
    </template>

    <template #[`item.actions`]="{ item }">
      <VehicleDetailOptionsButton
        :vehicle="item"
        @vehicle-edit-pressed="(vehicle: IVehicle) => emit('vehicle-edit-pressed', vehicle)"
        @vehicle-delete-pressed="(vehicle: IVehicle) => emit('vehicle-delete-pressed', vehicle)"
      />
    </template>

    <template v-slot:no-data>
      <NoDataAvailablePlaceholder
        header="No vehicles available"
        header-class="font-weight-medium text-h5"
      >
        <template #prepend>
          <v-card
            variant="flat"
            class="mask-bottom-card mb-3"
            color="transparent"
            width="180"
            height="120"
          >
            <v-list
              style="color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity))"
              variant="outlined"
              density="compact"
              lines="two"
              class="pt-0"
            >
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="green-darken-2 text-white">
                    JD
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="amber-darken-2 text-white">
                    DM
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
            </v-list>
          </v-card>
        </template>

        <template #description>
          <p class="mt-0 mb-6 text-caption text-medium-emphasis">
            <span v-if="currentUser?.hasPermission('fleet.add_vehicle')">
              Click
              <span class="text-primary">Create Vehicle</span> in the top right to create a vehicle
              make
            </span>
            <span v-else>
              You do not have the permission to create a vehicle. Contact your administrator for
              more details
            </span>
          </p>
        </template>
      </NoDataAvailablePlaceholder>
    </template>
  </v-data-table>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'

import type { IVehicle } from '@/models/fleet-management'
import type { ReadonlyTableHeaders } from '@/utils/tables'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import VehicleDetailOptionsButton from '@/components/fleet-management/vehicles/VehicleDetailOptionsButton.vue'

interface Props {
  vehicles: IVehicle[]
  search: string
  loading: boolean
}

interface Emit {
  (e: 'vehicle-selected', vehicle: IVehicle): void
  (e: 'vehicle-edit-pressed', vehicle: IVehicle): void
  (e: 'vehicle-delete-pressed', vehicle: IVehicle): void
}

const emit = defineEmits<Emit>()
defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const vehicleDataTableHeaders: ReadonlyTableHeaders = [
  {
    title: '#',
    align: 'start',
    key: 'index',
    width: 20,
    sortable: false
  },
  {
    title: 'Plate',
    align: 'start',
    key: 'plate',
    sortable: false
  },
  {
    title: 'Year',
    align: 'start',
    key: 'year'
  },
  {
    title: 'Model',
    align: 'start',
    key: 'model.name',
    sortable: false
  },
  {
    title: 'Make',
    align: 'start',
    key: 'model.make',
    sortable: false
  },

  { title: '', key: 'actions', align: 'center', sortable: false }
]

function generateRowProps(props: { index: number; item: IVehicle }) {
  return {
    // apply click listener to row props to open detail drawer
    onClick: () => emit('vehicle-selected', props.item)
  }
}
</script>
