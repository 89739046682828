import type { AxiosInstance } from 'axios'
import axios from '@/axios'

import type { IVehicleMakeData, IVehicleModelData } from '@/models/fleet-management/vehicle'
import type { IVehicleMakeService } from './vehicle-make.types'

/**
 * A class representing a VehicleMakeService API handler.
 */
export class VehicleMakeService implements IVehicleMakeService {
  private endpoint: string

  // VehicleMakeService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/fleet/makes/'
  }

  // Method to fetch a list of vehicleMakes from the API
  fetchVehicleMakes(): Promise<IVehicleMakeData[]> {
    return this.axios.get(`${this.endpoint}`).then(({ data }) => data)
  }

  // Method to fetch a single vehicleMake by their ID from the API
  fetchVehicleMake(id: number): Promise<IVehicleMakeData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new vehicleMake on the API
  createVehicleMake(data: IVehicleMakeData): Promise<IVehicleMakeData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing vehicleMake on the API
  updateVehicleMake(data: IVehicleMakeData): Promise<IVehicleMakeData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a vehicleMake by their ID from the API
  deleteVehicleMake(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // MODELS

  // Method to fetch all vehicleModels from the API
  fetchAllVehicleModels(): Promise<IVehicleModelData[]> {
    return this.axios.get(`${this.endpoint}models/all/`).then(({ data }) => data)
  }

  // Method to fetch a list of vehicleModels from the API
  fetchVehicleModels(makeId: number): Promise<IVehicleModelData[]> {
    return this.axios.get(`${this.endpoint}${makeId}/models/`).then(({ data }) => data)
  }

  // Method to fetch a single vehicleModel by their ID from the API
  fetchVehicleModel(makeId: number, id: number): Promise<IVehicleModelData> {
    return this.axios.get(`${this.endpoint}${makeId}/models/${id}/`).then(({ data }) => data)
  }

  // Method to create a new vehicleModel on the API
  createVehicleModel(makeId: number, data: IVehicleModelData): Promise<IVehicleModelData> {
    return this.axios.post(`${this.endpoint}${makeId}/models/`, data).then(({ data }) => data)
  }

  // Method to update an existing vehicleModel on the API
  updateVehicleModel(makeId: number, data: IVehicleModelData): Promise<IVehicleModelData> {
    return this.axios
      .patch(`${this.endpoint}${makeId}/models/${data.id}/`, data)
      .then(({ data }) => data)
  }

  // Method to delete a vehicleModel by their ID from the API
  deleteVehicleModel(makeId: number, id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${makeId}/models/${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the VehicleMakeService
const vehicleMakeService = new VehicleMakeService(axios)
export default vehicleMakeService
