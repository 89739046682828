import { RouterView, type RouteRecordRaw } from 'vue-router'

import AccountLayout from '@/layouts/AccountLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import BlankLayout from '@/layouts/BlankLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import DashboardView from '@/views/dashboard/DashboardView.vue'

// PAGES
import CCTVReportListView from '@/views/report/CCTVReportListView.vue'
import ClientsListView from '@/views/clients/ClientsListView.vue'
import ContactListView from '@/views/contact/ContactListView.vue'
import EmployeeListView from '@/views/employee/EmployeeListView.vue'
import CCTVReportDetailView from '@/views/report/CCTVReportDetailView.vue'
import IncidentReportListView from '@/views/report/IncidentReportListView.vue'
import IncidentReportDetailView from '@/views/report/IncidentReportDetailView.vue'
import GuardReportListView from '@/views/report/GuardReportListView.vue'
import GuardReportDetailView from '@/views/report/GuardReportDetailView.vue'
import VehicleLogsListView from '@/views/fleet_management/VehicleLogsListView.vue'
import PostOrderDetailView from '@/views/post_order/PostOrderDetailView.vue'
import VehiclesListView from '@/views/fleet_management/VehiclesListView.vue'
import VehiclesTypesListView from '@/views/fleet_management/VehiclesTypesListView.vue'
// PAGES

/*
  meta: {
    layout: DefaultLayout           use to dynamically control the layout used (default is DefaultLayout)
    permissions: [                  control pages based on user permission
      'accounts.view_user',
      'accounts.add_user'
    ]
    title: 'title'                  the name showed in menu and breadcrumb
    icon: 'icon-name'               the icon showed in the sidebar
    hidden: true                    controls visibility of menu in sidebar (default is false)
    public: true                    route can be accessed without user authentication (default is false/private)
  }
*/

// Routes that are always available
export const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'dashboard',
    meta: {
      layout: DefaultLayout,
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      hidden: false
    },
    component: DashboardView
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: AuthLayout, hidden: true, public: true },
    component: () => import('@/views/auth/LoginView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { layout: AuthLayout, hidden: true, public: true },
    component: () => import('@/views/auth/ForgotPasswordView.vue')
  },
  {
    path: '/auth/password/reset/confirm/:uid/:token/',
    name: 'confirm-reset-password',
    meta: { layout: AuthLayout, hidden: true, public: true },
    component: () => import('@/views/auth/ResetPasswordView.vue')
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    meta: { layout: AccountLayout, hidden: true },
    component: () => import('@/views/user/UserProfileView.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    meta: { layout: AccountLayout, hidden: true },
    component: () => import('@/views/user/ChangeUserPassword.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: { layout: BlankLayout, hidden: true, public: true },
    component: () => import('@/views/privacy/PrivacyView.vue')
  },
  {
    // Wildcard route for 404 page
    path: '/:catchAll(.*)', // Matches any route that hasn't been defined
    meta: { layout: BlankLayout, hidden: true, public: false },
    component: () => import('@/views/errors/404NotFound.vue')
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user permissions
 */
export const asyncRoutes: RouteRecordRaw[] = [
  // TODO: LH  - 2023-12-12  - DEMO ONLY PAGE REMOVE FROM STATIC ROUTE AFTER
  {
    path: '/reports',
    meta: {
      layout: DefaultLayout,
      title: 'Reports',
      icon: 'mdi-text-box',
      permissions: [
        'cctv_reports.view_cctvreport',
        'guard_reports.view_guardreport',
        'incident_reports.view_incidentreport'
      ]
    },
    children: [
      {
        path: 'cctv',
        name: 'cctv',
        component: CCTVReportListView,
        meta: {
          layout: DefaultLayout,
          title: 'CCTV Reports',
          icon: 'mdi-cctv',
          permissions: ['cctv_reports.view_cctvreport']
        }
      },
      {
        path: 'cctv/:id',
        name: 'cctv-detail',
        component: CCTVReportDetailView,
        meta: {
          layout: DefaultLayout,
          title: 'CCTV Report Detail',
          icon: 'mdi-cctv',
          hidden: true,
          permissions: ['cctv_reports.view_cctvreport']
        }
      },
      {
        path: 'guard',
        name: 'guard',
        component: GuardReportListView,
        meta: {
          layout: DefaultLayout,
          title: 'Guard Reports',
          icon: 'mdi-shield-account',
          permissions: ['guard_reports.view_guardreport']
        }
      },
      {
        path: 'guard/:id',
        name: 'guard-detail',
        component: GuardReportDetailView,
        meta: {
          layout: DefaultLayout,
          title: 'Guard Report Detail',
          icon: 'mdi-shield-account',
          hidden: true,
          permissions: ['guard_reports.view_guardreport']
        }
      },
      {
        path: 'incident',
        name: 'incident',
        component: IncidentReportListView,
        meta: {
          layout: DefaultLayout,
          title: 'Incident Reports',
          icon: 'mdi-alert-octagon',
          permissions: ['incident_reports.view_incidentreport']
        }
      },
      {
        path: 'incident/:id',
        name: 'incident-detail',
        component: IncidentReportDetailView,
        meta: {
          layout: DefaultLayout,
          title: 'Incident Report Detail',
          icon: 'mdi-alert-octagon',
          hidden: true,
          permissions: ['incident_reports.view_incidentreport']
        }
      },
      {
        path: 'mobile',
        name: 'mobile',
        component: CCTVReportListView,
        meta: {
          layout: DefaultLayout,
          title: 'Mobile Response',
          icon: 'mdi-car-emergency',
          permissions: ['alarm_reports.view_alarmreport']
        }
      }
    ]
  },

  {
    path: '/crm',
    meta: {
      layout: DefaultLayout,
      title: 'Clients',
      icon: 'mdi-book-open',
      permissions: ['clients.view_client', 'contacts.view_contact']
    },
    children: [
      {
        path: 'clients',
        name: 'clients',
        component: ClientsListView,
        meta: {
          layout: DefaultLayout,
          title: 'Clients',
          icon: 'mdi-domain',
          permissions: ['clients.view_client']
        }
      },
      {
        path: 'clients/:clientId',
        name: 'client-detail',
        component: RouterView,
        meta: {
          layout: DefaultLayout,
          title: 'Client',
          icon: 'mdi-domain',
          hidden: true,
          permissions: ['post_orders.view_postorder']
        },
        children: [
          {
            path: 'site/:siteId/post-orders/:id/instructions',
            name: 'post-order-instruction-detail',
            component: PostOrderDetailView,
            meta: {
              layout: DefaultLayout,
              title: 'Post Order Instruction Detail',
              icon: 'mdi-alert-octagon',
              hidden: true,
              permissions: ['post_orders.view_postorderinstructions']
            }
          }
        ]
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: ContactListView,
        meta: {
          layout: DefaultLayout,
          title: 'Contacts',
          icon: 'mdi-account',
          permissions: ['contacts.view_contact']
        }
      }
    ]
  },

  {
    path: '/employees',
    name: 'employees',
    component: EmployeeListView,
    meta: {
      layout: DefaultLayout,
      title: 'Employees',
      icon: 'mdi-account-multiple',
      permissions: ['employees.view_employee']
    }
  },
  {
    path: '/fleet-management',
    meta: {
      layout: DefaultLayout,
      title: 'Fleets',
      icon: 'mdi-car',
      permissions: [
        'fleet.view_vehicle',
        'fleet.view_vehiclelog',
        'fleet.view_vehiclemodel',
        'fleet.view_vehiclemake'
      ]
    },
    children: [
      {
        path: '/vehicles-logs',
        name: 'vehicles-logs',
        component: VehicleLogsListView,
        meta: {
          layout: DefaultLayout,
          title: 'Vehicle Logs',
          icon: 'mdi-car-multiple',
          permissions: ['fleet.view_vehiclelog']
        }
      },
      {
        path: '/vehicles',
        name: 'vehicles',
        component: VehiclesListView,
        meta: {
          layout: DefaultLayout,
          title: 'Vehicles',
          icon: 'mdi-car',
          permissions: ['fleet.view_vehicle']
        }
      },
      {
        path: '/vehicles-types',
        name: 'vehicles-types',
        component: VehiclesTypesListView,
        meta: {
          layout: DefaultLayout,
          title: 'Vehicles Types',
          icon: 'mdi-car-cog',
          permissions: ['fleet.view_vehiclemodel', 'fleet.view_vehiclemake']
        }
      }
    ]
  }
]
